exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-templates-aboutus-js": () => import("./../../../src/templates/aboutus.js" /* webpackChunkName: "component---src-templates-aboutus-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-carriers-by-country-js": () => import("./../../../src/templates/carriers_by_country.js" /* webpackChunkName: "component---src-templates-carriers-by-country-js" */),
  "component---src-templates-carriers-list-page-js": () => import("./../../../src/templates/carriers_list_page.js" /* webpackChunkName: "component---src-templates-carriers-list-page-js" */),
  "component---src-templates-click-n-ship-js": () => import("./../../../src/templates/click-n-ship.js" /* webpackChunkName: "component---src-templates-click-n-ship-js" */),
  "component---src-templates-comparison-page-js": () => import("./../../../src/templates/comparison_page.js" /* webpackChunkName: "component---src-templates-comparison-page-js" */),
  "component---src-templates-comparison-page-v-2-js": () => import("./../../../src/templates/comparison_page_v2.js" /* webpackChunkName: "component---src-templates-comparison-page-v-2-js" */),
  "component---src-templates-demo-js": () => import("./../../../src/templates/demo.js" /* webpackChunkName: "component---src-templates-demo-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-partners-js": () => import("./../../../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-platforms-js": () => import("./../../../src/templates/platforms.js" /* webpackChunkName: "component---src-templates-platforms-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing_page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-solutions-enterprise-js": () => import("./../../../src/templates/solutions-enterprise.js" /* webpackChunkName: "component---src-templates-solutions-enterprise-js" */),
  "component---src-templates-solutions-smallbusiness-js": () => import("./../../../src/templates/solutions-smallbusiness.js" /* webpackChunkName: "component---src-templates-solutions-smallbusiness-js" */),
  "component---src-templates-solutions-usecases-js": () => import("./../../../src/templates/solutions-usecases.js" /* webpackChunkName: "component---src-templates-solutions-usecases-js" */),
  "component---src-templates-submission-js": () => import("./../../../src/templates/submission.js" /* webpackChunkName: "component---src-templates-submission-js" */)
}

